<template>
  <div>
    <!-- <header-view :onParallax="true"></header-view> -->
    <v-container fluid>
      <v-layout row wrap>
        <v-flex lg12 xs12>
          <v-container>
            <v-row no-gutters>
              <v-col cols="12" lg="12">
                <v-layout row wrap justify-center class="pt-4 pt-lg-5 pb-1 px-3 px-lg-0">
                  <v-flex lg3 xs12 class="pr-lg-2 pb-2 pb-lg-0">
                    <v-menu v-model="filterObj.checkInMenu" :close-on-content-click="false" max-width="290">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field :value="computedCheckInDate" clearable label="Check-in" readonly
                          v-bind="attrs" v-on="on" @click:clear="filterObj.checkInDate = null" outlined dense
                          prepend-inner-icon="mdi-calendar-month" :hide-details="xsOnly"
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="filterObj.checkInDate" @change="filterObj.checkInMenu = false" :min="today"></v-date-picker>
                    </v-menu>
                  </v-flex>
                  <v-flex lg3 xs12 class="pr-lg-2 pb-2 pb-lg-0">
                    <v-menu v-model="filterObj.checkOutMenu" :close-on-content-click="false" max-width="290">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field :value="computedCheckOutDate" clearable label="Check-out" readonly
                          v-bind="attrs" v-on="on" @click:clear="filterObj.checkOutDate = null" outlined dense
                          prepend-inner-icon="mdi-calendar-month" :hide-details="xsOnly"
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="filterObj.checkOutDate" @change="filterObj.checkOutMenu = false"
                      :min="filterObj.checkInDate || today"></v-date-picker>
                    </v-menu>
                  </v-flex>
                  <v-flex lg3 xs9 class="pb-2 pr-lg-2 pb-lg-0">
                    <v-menu v-model="filterObj.travelersMenu" :close-on-content-click="false" z-index="5">
                      <template #activator="{ on, attrs }">
                        <v-text-field label="Travelers" outlined dense
                        prepend-inner-icon="mdi-account-multiple" v-on="on" v-bind="attrs" readonly
                        :value="getTravelers()" hint="(A) - Adults, (C) - Children, (R) - Rooms"
                        persistent-hint
                        ></v-text-field>
                      </template>
                      <v-card>
                        <v-card-title class="primary white--text font-weight-bold pa-2 pl-3">
                          <v-icon color="white" class="mr-2"> mdi-account-multiple </v-icon>
                          <span> Travelers </span>
                          <v-spacer></v-spacer>
                          <!-- <v-btn icon color="white" @click="filterObj.travelersMenu = false">
                            <v-icon> mdi-close-circle </v-icon>
                          </v-btn> -->
                        </v-card-title>
                        <v-card-text class="px-0 py-0" style="max-height: 50vh; overflow-y: auto;">
                          <div class="pa-2 pb-0" v-for="(room, index) in filterObj.rooms" :key="index">
                            <v-layout justify-space-between class="py-1">
                              <v-flex>
                                <span class="caption font-weight-bold"> Room {{ index + 1 }} </span>
                              </v-flex>
                              <v-flex class="text-right">
                                <v-btn icon x-small width="15px" height="15px" outlined class="grey darken-2"
                                @click="filterObj.rooms.length > 1 ? filterObj.rooms.splice(index, 1) : ''">
                                  <v-icon size="10" color="white"> mdi-minus </v-icon>
                                </v-btn>
                              </v-flex>
                            </v-layout>
                            <div class="room-group px-2">
                              <v-layout class="py-2" v-for="(item) in Object.keys(room)" :key="item">
                                <v-flex lg6 xs6>
                                  <span class="font-weight-bold caption"> {{ item }} </span>
                                </v-flex>
                                <v-flex lg6 xs6 class="text-right">
                                  <v-btn x-small outlined icon height="15px" width="15px" @click="room[item] ? room[item] -= 1 : ''">
                                    <v-icon size="12"> mdi-minus </v-icon>
                                  </v-btn>
                                  <span class="mx-1"> {{ room[item] }} </span>
                                  <v-btn x-small outlined icon height="15px" width="15px" @click="room[item] += 1">
                                    <v-icon size="12"> mdi-plus </v-icon>
                                  </v-btn>
                                </v-flex>
                              </v-layout>
                            </div>
                          </div>
                          <div class="py-2 px-2 text-right">
                            <v-btn x-small class="primary py-3" dark @click="filterObj.rooms.push({ adults: 0, children: 0 })">
                              <v-icon size="15" class="mr-1"> mdi-plus </v-icon> Room
                            </v-btn>
                          </div>
                          <v-divider></v-divider>
                        </v-card-text>
                        <v-card-actions class="py-2 px-5 px-lg-2">
                          <v-btn color="success" block @click="filterObj.travelersMenu = false"> Done </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-menu>
                  </v-flex>
                  <v-flex lg1 class="pb-6 pb-lg-0 text-right">
                    <v-btn color="success" height="38" elevation="2" @click="search"> Search </v-btn>
                  </v-flex>
                </v-layout>
              </v-col>
            </v-row>
          </v-container>
        </v-flex>
      </v-layout>
      <v-layout row wrap class="my-1">
        <v-flex lg3 class="pa-3">
          <template v-if="xsOnly">
            <v-btn color="primary" outlined dark @click="filterMenu = true">
              <v-icon class="mr-1"> mdi-filter </v-icon>
              <span> Sort &  Filter </span>
            </v-btn>
          </template>
          <room-filter :items="data" v-else></room-filter>
        </v-flex>
        <v-flex lg9 class="justify-center grey lighten-5">
          <v-container fluid class="px-4 pt-2 py-lg-5">
            <v-layout row>
              <v-flex lg4 v-for="(item, index) in data" :key="index">
                <!-- {{ item }} -->
                <v-hover v-slot="{ hover }">
                  <v-card :class="[item.available ? 'cursor-pointer' : '', 'ma-2']" :elevation="hover ? '10' : xsOnly ? '2' : '1'"
                  style="position: relative;" :to="item.available ? `/rooms/${item.key}` : ''">
                    <v-overlay absolute v-if="!item.available" opacity="0.65">
                      <span class="font-weight-black title"> We are sold out </span>
                    </v-overlay>
                    <v-card-title class="pa-0" style="position: relative;">
                      <img :src="`${imageBaseUrl}images/Hotels/${item.profileImg}`" width="100%" height="180vh">
                      <v-layout style="position: absolute; top: 0; right: 0; width: 100%;" fill-height justify-space-between align-center
                      class="px-4" v-if="hover && config.multiple_profile">
                        <v-flex xs6 lg6>
                          <v-btn icon outlined elevation="8">
                            <v-icon> mdi-chevron-left </v-icon>
                          </v-btn>
                        </v-flex>
                        <v-flex xs6 lg6 class="text-right">
                          <v-btn icon outlined elevation="8">
                            <v-icon> mdi-chevron-right </v-icon>
                          </v-btn>
                        </v-flex>
                      </v-layout>
                    </v-card-title>
                    <v-card-text class="pa-2 text-center">
                      <p class="pt-2 font-weight-black grey--text text--darken-2" style="font-size: 1.4rem;"> {{ item.title }} </p>
                      <p class="my-0 font-weight-black indigo--text text--darken-1" style="font-size: 1.05rem;"> ₹{{ item.perDayPrice }} </p>
                      <p style="font-size: 0.7rem;" class="ma-0"> ( Includes all taxes & fees ) </p>
                    </v-card-text>
                    <v-card-actions class="pt-0 pb-3 px-3">
                      <v-spacer></v-spacer>
                      <div>
                        <v-rating background-color="grey" color="teal"
                        empty-icon="mdi-star-outline" full-icon="mdi-star" half-icon="mdi-star-half-full"
                        half-increments size="17" :value="item.review.point" dense readonly small></v-rating>
                      </div>
                      <div>
                        <span class="mx-2 caption grey--text text--darken-1 font-weight-bold">
                          ( {{ item.review.total }} Ratings )
                        </span>
                      </div>
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-hover>
              </v-flex>
            </v-layout>
          </v-container>
        </v-flex>
      </v-layout>
    </v-container>
    <v-dialog v-model="filterMenu" persistent>
      <v-card>
        <v-card-title class="primary white--text pa-3">
          <v-icon color="white" class="mr-1"> mdi-filter </v-icon>
          <span class="body-1 font-weight-bold"> Sort & Filter </span>
          <v-spacer></v-spacer>
          <v-btn icon color="white" @click="filterMenu = false">
            <v-icon> mdi-close-circle </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pa-3 pb-0">
          <room-filter :items="data" class="pb-3"></room-filter>
          <v-divider class="grey"></v-divider>
        </v-card-text>
        <v-card-actions class="py-3">
          <v-spacer></v-spacer>
          <v-btn color="success" elevation="2" @click="search"> Apply </v-btn>
          <v-btn outlined color="error" @click="filterMenu = false"> Cancel </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import moment from 'moment'
export default {
  data () {
    return {
      config: {
        multiple_profile: false
      },
      filterObj: {
        rooms: [{
          adults: 0,
          children: 0
        }, {
          adults: 0,
          children: 0
        }]
      },
      filterMenu: false,
      imageBaseUrl: process.env.VUE_APP_IMAGE_BASEURL,
      today: moment().format('YYYY-MM-DD'),
      data: [{
        title: 'Yelagiri Farm House',
        key: 'yelagiri-farm-house',
        profileImg: 'boatHouse.jpg',
        perDayPrice: 1250,
        review: {
          point: 3.5,
          total: 7
        },
        available: true
      }, {
        title: 'Vijay Villa',
        key: 'vijay-villa',
        profileImg: 'boatHouse.jpg',
        perDayPrice: 1500,
        review: {
          point: 3,
          total: 12
        },
        available: true
      }, {
        title: 'Thrill Valley',
        key: 'thrill-valley',
        profileImg: 'boatHouse.jpg',
        perDayPrice: 2000,
        review: {
          point: 4,
          total: 17
        },
        available: false
      }, {
        title: 'Fundera Aqua Stay',
        key: 'fundera-aqua-stay',
        profileImg: 'boatHouse.jpg',
        perDayPrice: 2500,
        review: {
          point: 3.5,
          total: 15
        },
        available: false
      }, {
        title: 'Sterling',
        key: 'sterling',
        profileImg: 'boatHouse.jpg',
        perDayPrice: 4500,
        review: {
          point: 4.5,
          total: 20
        },
        available: false
      }, {
        title: 'Kumaraja Palace',
        key: 'kumaraja-palace',
        profileImg: 'boatHouse.jpg',
        perDayPrice: 5500,
        review: {
          point: 4,
          total: 17
        },
        available: false
      }]
    }
  },
  components: {
    // 'header-view': () => import('@/components/Header.vue'),
    'room-filter': () => import('@/components/rooms/Filters.vue')
  },
  computed: {
    computedCheckInDate () {
      return this.filterObj.checkInDate ? moment(this.filterObj.checkInDate).format('dddd, MMM Do YYYY') : ''
    },
    computedCheckOutDate () {
      return this.filterObj.checkOutDate ? moment(this.filterObj.checkOutDate).format('dddd, MMM Do YYYY') : ''
    }
  },
  mounted () {
    this.$root.$on('onFilterChange', (data) => {
      console.log(data, 'sideFilters')
    })
  },
  methods: {
    search () {
      console.log(this.filterObj)
    },
    getTravelers () {
      const result = this.filterObj.rooms.reduce((a, b) => ({ adults: a.adults + b.adults, children: a.children + b.children }))
      return `${result.adults} (A), ${result.children} (C) - ${this.filterObj.rooms.length} (R)`
    }
  },
  beforeDestroy () {
    this.$root.$off('onFilterChange')
  }
}
</script>
<style scoped>
  .room-group {
    border: 1px dashed lightgray;
  }
</style>
